import React, { useEffect, useState } from 'react';
import DropDown from '../../../components/DropDown';
import TableComponent from '../../../components/TableComponent';
import classNames from 'classnames';
import KnowledgeBaseTree from './knowledgeBaseTree';
import { TableIconicButton, TextInputRow } from '../../../reactcomponents/Form';
import FilterText from '../admin';
import {
	KBT_CATEGORY,
	KBT_LIBRARY,
	KBT_QUESTION,
	KBT_SUGGESTION,
	KBT_RATING,
	KB_TYPE
} from '../../../common/v5/constants';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { CustomDraggableBox } from '../../../reactcomponents/DragAndDropBox';
import { Spinner } from '../../../reactcomponents/Spinner';
import { DeletePopup } from "../../../reactcomponents/Dialog";

class KnowledgeBaseListMouseOver extends React.Component {
	render() {
		if ((this.props.libraries && this.props.libraries.length > 0) ||
			(this.props.categories && this.props.categories.length > 0) ||
			(this.props.areas && this.props.areas.length > 0)) {
			var areas = [];
			var libraries = [];
			var categories = [];

			if (this.props.areas && this.props.areas.length > 0) {
				areas = this.props.areas.map(function (area, i) {
					return (
						<li key={"area_" + i}>{area.name}</li>
					);
				});
			}
			if (this.props.libraries && this.props.libraries.length > 0) {
				libraries = this.props.libraries.map(function (library, i) {
					return (
						<li key={"library_" + i}>{library.name}</li>
					);
				});
			}
			if (this.props.categories && this.props.categories.length > 0) {
				categories = this.props.categories.map(function (category, i) {
					return (
						<li key={"categories_" + i}>{category.name}</li>
					);
				});
			}

			var styleAreas = {};
			var styleLibraries = {};
			var styleCategories = {};

			if (areas.length <= 0) {
				styleAreas.display = 'none';
			}
			if (libraries.length <= 0) {
				styleLibraries.display = 'none';
			}
			if (categories.length <= 0) {
				styleCategories.display = 'none';
			}

			return (
				<div>
					<div style={styleAreas}>
						<div><strong>{I('Areas')}</strong></div>
						<ul>{areas}</ul>
					</div>
					<div style={styleLibraries}>
						<div><strong>{I('Knowledge bases')}</strong></div>
						<ul>{libraries}</ul>
					</div>
					<div style={styleCategories}>
						<div><strong>{I('Categories')}</strong></div>
						<ul>{categories}</ul>
					</div>
				</div>
			);
		} else {
			return (
				<div>{I('Unused.')}</div>
			);
		}
	}
};

class KnowledgeBaseList extends React.Component {
	constructor(props) {
		super(props);
		this.loadList = this.loadList.bind(this);
		this.state = {
			currentOffset: 0,
			edit: {
				show: false,
				title: '',
				data: {}
			},
			itemsPerPage: 13,
			list: [],
			more: false,
			searchOption: '0',
			searchText: '',
			active: false
		}
	}
	componentDidMount() {
		if(!this.props.forErrand) {
			this.loadList(0, this.state.itemsPerPage);
		}
	}
	componentDidUpdate(prevProps) {
		if(!cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")) {
			if(!this.props.forErrand) {
				if (this.props.edit != prevProps.edit) {
					this.handleEdit(this.props.edit)
				};
				if (this.props.deletedId != prevProps.deletedId ||
					this.props.savedId != prevProps.savedId) {
					this.reloadList();
					if (typeof this.props.resetTabAndReloadTree === 'function') {
						this.props.resetTabAndReloadTree();
					};
				};
			}
		}
	}
	loadList = (offset, limit, search, option, more) => {
		var parameters = {};
		parameters.offset = offset;
		parameters.limit = limit;
		parameters.option = option;
		if (search) {
			parameters.search = search;
		};
		if (!this.props.showMore) {
			this.setState({
				currentOffset: 0
			})
		} else {
			this.setState({
				currentOffset: offset
			})
		}
		if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
			parameters.offset = 0;
			parameters.limit = 100;
		}
		let listType = this.props.type;
		if (this.props.type === "KBT_QUESTION_ALL") {
			listType = KBT_QUESTION;
		}
		return this.props.onLoadList(listType, parameters, more);
	}
	reloadList = () => {
		var limit = this.state.currentOffset + this.state.itemsPerPage;
		this.loadList(0, limit, this.state.searchText, this.state.searchOption);
	}
	performSearch = () => {
		this.loadList(0, this.state.itemsPerPage, this.state.searchText, this.state.searchOption);
	}
	handleSearchKeyDown = (event) => {
		if (event.keyCode == 13 /* enter */) {
			this.performSearch();
		}
	}
	handleSearchTextChange = (event) => {
		this.setState({ searchText: event.target.value });
	}
	handleSearchOptionChange = (option) => {
		this.searchOption = option;
		this.setState({ searchOption: option })
		this.reloadList();
	}
	handleMoreClick = () => {
		var offset = this.state.currentOffset + this.state.itemsPerPage;
		this.loadList(offset, this.state.itemsPerPage, this.state.searchText, this.state.searchOption, true);
	}
	handleEdit = (id) => {
		let active = true;
		if (id > 0) {
			this.props.onHandleEdit(this.props.type, id)
		} else if (id === 0) {
			this.props.onCreateNew(this.props.createTitle);
		}
		this.setState({ active: active })
	}
	handleCancel = () => {
		let active = false;
		this.props.onResetEdit();
		this.setState({ active: active })
	}
	handleSave = (data) => {
		if (data.name) {
			data.name = data.name.trim();
		}
		if (data.subject) {
			data.subject = data.subject.trim();
		}
		this.props.onHandleSave(this.props.type, data);
	}
	handleRemove = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete this knowledge base?');
		if (this.props.type == KBT_CATEGORY) {
			confirmDelMsg = I('Are you sure you want to delete this category?');
		}
		else if (this.props.type == KBT_LIBRARY) {
			confirmDelMsg = I('Are you sure you want to delete this knowledge base?');
		}
		else if (this.props.type == KBT_QUESTION) {
			confirmDelMsg = I('Are you sure you want to delete this question?');
		}
		else if (this.props.type == KBT_SUGGESTION) {
			confirmDelMsg = I('Are you sure you want to delete this suggestion?');
		}
		else if (this.props.type == KBT_RATING) {
			confirmDelMsg = I('Are you sure you want to delete this rating?');
		}
		if (confirm(confirmDelMsg)) {
			this.props.onHandleRemove(this.props.type, id)
		}
	}
	handleCopyRow = (event) => {
		this.props.onCopyRow(event, this.props.type)
	}
	render() {
		var columns = [];
		var timeColumns = false;
		var topColumns = false;
		var agentColumns = false;
		var errandColumns = false;
		var activeButton = "";
		let tableSize = parseInt(window.innerHeight / 66.38);
		let extraTableClass = "list-table col-lg-12";
		if(this.props.new) {
			extraTableClass = "list-table draggable-box";
		}
		const { active } = this.state;
		columns.push({ header: this.props.tableColumnHeader, key: 'name', id: 'id' });
		// if (this.props.showColumnCopy) {
		// 	columns.push({ type: 'copyLink', key: 'copy', id: 'id' });
		// }
		if (this.props.showColumnVote) {
			columns.push({ type: 'thumbsUp', key: 'voteUp', id: 'id' });
			columns.push({ type: 'thumbsDown', key: 'voteDown', id: 'id' });
		}

		if (this.props.showPreview) {
			columns[0].type = 'showPreview';
			columns[0].hoverTitle = I('Added in');
		}

		var moreStyle = {}, editStyle = {}, createNewStyle = { textAlign: 'right' }, dropDownStyle = {};
		var searchClassName = 'col-lg-6';
		if (!this.props.more) moreStyle.display = 'none';
		if (this.props.edit && !this.props.edit.show) { editStyle.display = 'none'; activeButton = ""; }
		if (this.props.edit && this.props.edit.show) { dropDownStyle.display = 'none'; activeButton = "active"; }
		if (!this.props.allowCreateNew) {
			searchClassName = 'col-lg-12';
			createNewStyle.display = 'none';
		}
		var searchOptions = null;
		if (this.props.showSearchOptions) {
			var items = [
				{ id: 0, name: I('Sort By') },
				{ id: 1, name: I('Used') },
				{ id: 2, name: I('Unused') },
				{ id: 3, name: I('Time controlled') },
				{ id: 4, name: I('Top list') }
			];
			if (this.props.showKeyboardShortcuts) {
				items.push({ id: 5, name: I('Keyboard shortcut') });
			}
			searchOptions = (
				<DropDown
					id="LibraryListSearchOptions"
					ref="LibraryListSearchOptions"
					onChange={this.handleSearchOptionChange}
					items={items}
					fields={{ id: 'id', name: 'name' }}
					multiSelect={false}
					wantSelectAll={false}
					wantSelectNone={false}
					wantShowDone={false}
					selectedItems={this.state.searchOption}
					name="" />
			);
		}
		if (this.props.list) {
			if (this.props.list.length >= tableSize) {
				extraTableClass = "list-table col-lg-12 x-large";
				if (window.innerHeight <= 880){
					extraTableClass = "list-table col-lg-12 x-small";
				}else if (window.innerHeight <= 960){
					extraTableClass = "list-table col-lg-12 small";
				}else if (window.innerHeight <= 1080){
					extraTableClass = "list-table col-lg-12 large";
				}else if (window.innerHeight <= 1240){
					extraTableClass = "list-table col-lg-12 x-large";
				}
			}
			if(this.props.list && this.props.list.length > 0) {
				this.props.list.map((item) => {
					item.title = item.name;
					if (this.props.type == KBT_SUGGESTION) {
						if (item.agentName !== undefined) {
							agentColumns = true;
						}
						if (item.displayId !== undefined) {
							errandColumns = true;
						}
					}
					if (this.props.showExtraColumn) {
						if (item.timeControlled) {
							timeColumns = true;
						}
						if (item.showInTopList) {
							topColumns = true;
						}

						item.extra = '';
						item.time = '';
						item.top = '';
						item.time += (item.timeControlled ? '<i class="fa fa-clock-o" style="padding: 0 3px;"></i>' : '');
						item.top += (item.showInTopList ? '<i class="icon-thumbs-up" style="padding: 0 3px;"></i>' : '');
						if (this.props.showKeyboardShortcuts) {
							item.extra += (item.keyboardShortcut ? '<i class="fa fa-keyboard-o"></i>' : '');
						}
					}
					item.action =
						'<div style="white-space:nowrap; display:inline; margin-right:10px">' +
						'<i class="fa ' + this.props.removeIcon + '"></i> ' +
						'</div>' +
						'<div style="white-space:nowrap; display:inline">' +
						'<i class="fas ' + this.props.copyIcon + '"></i> ' +
						'</div>';
				})
			}
		}
		if (timeColumns) {
			columns.push({ header: '<i class="fa fa-clock-o"></i>', type: 'time', key: 'time' });
		}
		if (topColumns) {
			columns.push({ header: '<i class="icon-thumbs-up"></i>', type: 'top', key: 'top' });
		}
		if (agentColumns) {
			columns.push({ header: 'Agent', type: 'agentName', key: 'agentName' });
		}
		if (errandColumns) {
			columns.push({ header: 'Errand', type: 'errand', key: 'displayId' });
		}
		if (this.props.showExtraColumn) {
			if (this.props.showKeyboardShortcuts) {
				columns.push({ header: 'Keyboard Shortcut', type: 'shortcut', key: 'keyboardShortcut' });
			}
		}
		if (this.props.showColumnRemove) {
			columns.push({ header: I('Action'), type: 'actionCombineLink', key: 'action', id: 'id' });
		}
		return (
			<div id="wrapper" className="admin-profile">
				<div id="page-wrapper">
				{
						!cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") &&
						<div className="row searchBoxOptions">
							<div className={searchClassName}>
								<div className="form-group input-group">

									{/* <input
									className="form-control agentSearchBox input-sm"
									data-qa-id="QA_knowledgebase_search_input"
									type="text"
									placeholder={I("Search for") + "..."}
									defaultValue=""
									onKeyDown={this.handleSearchKeyDown}
									onChange={this.handleSearchTextChange} /> */}
									<TextInputRow
										id="QA_knowledgebase_search_input"
										name="agentSearchBox"
										className="form-control agentSearchBox input-sm"
										icon="icon-search"
										placeholder={I("Search for...")}
										value={this.state.searchText}
										onChange={this.handleSearchTextChange}
										onKeyDown={this.handleSearchKeyDown}
									/>
								</div>
							</div>
							<div className="col-lg-6" style={createNewStyle}>
								<span className="input-group-btn" data-qa-id="QA_knowledgebase_search_button" style={{ display: 'inline-flex' }}>
									{searchOptions}
								</span>
								<TableIconicButton
									type='button'
									className={'btn bg-transparent btn-round ' + activeButton}
									iconClass='icon-add'
									title={I('Create new')}
									onClick={() => this.handleEdit(0)}
								/>
							</div>
						</div>
					}
					<div className="kb-list-section row">
						<div className={extraTableClass}>
						{!this.props.new &&
								<TableComponent
								id={this.props.tableId}
								dataSource={this.props.list}
								htmlData={true}
								columns={columns}
								highlightRow={this.handleEdit}
								onDeleteRow={this.handleRemove}
								onHoverColumn={this.renderMouseOver}
								hoverFields={{ id: 'id', name: 'title' }}
								draggable={this.props.draggable}
								onDragStart={this.props.onDragStart}
								onCopyRow={this.handleCopyRow}
								paginate={true}
								pageSize={tableSize}
								enablePageSizeChange={false}
								onEditActionRow={this.handleEdit}
								knowledgeBase={this.props.knowledgeBase}
								pageV5={true}
								incompletePagination={this.props.incompletePagination || false}
							/>
							}
							{
								this.props.new &&
								<DraggableKnowledgeBaseBox
									forErrand={this.props.forErrand}
									popupMode={this.props.popupMode}
									droppableId={this.props.droppableId}
									nodeIdentifier={this.props.nodeIdentifier}
									disableDrag={this.props.disableDrag}
									activeLibrary={this.props.activeLibrary}
									activeCategory={this.props.activeCategory}
									list={this.props.list}
									fetchingList={this.props.fetchingLibrary}
									treeList={this.props.treeList}
									allLibraryList={this.props.allLibraryList}
									allCategoryList={this.props.allCategoryList}
									allQuestionList={this.props.allQuestionList}
									type={this.props.type}
									kbType={this.props.kbType}
									treeMode={this.props.treeMode}
									suggestMode={this.props.suggestMode}
									onFetchKnowledgeBase={this.props.onFetchKnowledgeBase}
									onResetKnowledgeBase={this.props.onResetKnowledgeBase}
									onResetCategory={this.props.onResetCategory}
									onBoxDragStart={this.props.onBoxDragStart}
									onEditKnowledgeBase={this.props.onEditKnowledgeBase}
									kbColors={this.props.kbColors}
									onRemoveNode={this.props.onRemoveNode}
									onClickNodeLink={this.props.onClickErrandId}
									onShowAnswerForErrand={this.props.onShowAnswerForErrand}
									onLoadSearchInLibrary={this.props.onLoadSearchInLibrary}
									showSearchResult={this.props.showSearchResult}
									onSaveParentInfo={this.props.onSaveParentInfo}
								/>
							}
							{
								this.props.fetchingQuestion ?
								<div className='loading-spinner'>
									<span className='label'>{I("Loading")}</span>
									<i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
								</div>
								:
								<div className="buttonShowMoreHistory"
									hidden={!this.props.showMore}
									onClick={this.handleMoreClick}>
									<div className='btn'>
										<span className="label">{I("Load more")}</span>
										<i className="icon-reload"></i>
									</div>
								</div>
							}
						</div>
						{/* <div className="list-form col-lg-6" style={editStyle}>
							{this.props.renderEdit(this)}
						</div>
						*/}
					</div>
				</div>
			</div>
		);
	}
	renderMouseOver = (id) => {
		for (var i = 0; i < this.state.list.length; i++) {
			var row = this.state.list[i];
			if (row.id == id) {
				return (
					<KnowledgeBaseListMouseOver
						libraries={row.parentLibraries}
						categories={row.parentCategories}
						areas={row.connectedAreas}
					/>
				);
			}
		}
		return (
			<KnowledgeBaseListMouseOver />
		);
	}
}

KnowledgeBaseList.defaultProps = {
	renderEdit: function (view) { },
	renderDropdown: function () { },
	allowCreateNew: true,
	copyIcon: 'fa-copy',
	copyName: I('Copy'),
	createTitle: '',
	draggable: false,
	editTitle: '',
	itemURL: '',
	listURL: '',
	removeIcon: 'fa-trash',
	removeName: I('Remove'),
	removeURL: '',
	showExtraColumn: false,
	showKeyboardShortcuts: false,
	showPreview: false,
	showSearchOptions: false,
}

const PrefixStyled = styled.div`
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: ${props => props.color};
	border-radius: 4px;
	margin-right: 10px;
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 12px;
`;

const ListBulletPoint = styled.span`
	display: inline-block;
	width: 4px;
	height: 4px;
	background-color: #6D6D6D;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: ${props => props.marginTop ? props.marginTop : 'unset'};
`;

const SuggestIcon = styled.i`
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 10px;
`;

//This is the main droppable style
const getDroppableStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightblue" : "",
	textAlign: 'center',
	padding: '10px',
	margin: '10px 0',
	textAlign: 'left',
	borderRadius: '5px',
	width: '100%'
});

const BreadCrumbLink = ({ title, onClick, children }) => (
	<a style={{ color: '#0C87F7', fontWeight: '700' }} href="#" title={title} onClick={onClick}>
		{children}
	</a>
);

const DraggableKnowledgeBaseBox = React.memo(({
	type,
	kbType,
	forErrand = false,
	popupMode,
	activeLibrary,
	activeCategory,
	list,
	treeMode,
	treeList,
	fetchingList,
	nodeIdentifier,
	disableDrag,
	suggestMode,
	droppableId,
	allLibraryList,
	allCategoryList,
	allQuestionList,
	onFetchKnowledgeBase,
	onResetKnowledgeBase,
	onResetCategory,
	onBoxDragStart,
	onEditKnowledgeBase,
	kbColors,
	onRemoveNode,
	onLoadSearchInLibrary,
	showSearchResult,
	onSaveParentInfo,
	...props
}) => {
	const [active, setActive] = useState("");
	const [activeId, setActiveId] = useState(0);
	const [toggleActiveLib, setToggleActiveLib] = useState(false);
	const [displayKeysStr, setDisplayKeysStr] = useState("");
	const [keys, setKeys] = useState([]);

	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [deleteWarning, setDeleteWarning] = useState("");
	const [deleteWarningMsg, setDeleteWarningMsg] = useState("");
	const [toBeDeleted , setToBeDeleted] = useState(0);

	const [freshPathLink, setFreshPathLink] = useState(false);
	const [parentCategory, setParentCategory] = useState("");
	const [parentCategoryId, setParentCategoryId] = useState(0);
	const [subLibName, setSubLibName] = useState("");
	const [subLibId, setSubLibId] = useState(0);

	const placeholder = "Drag and drop here";

	useEffect(() => {
		if(activeLibrary) {
			setSubLibId(0);
			setSubLibName("");
			setParentCategory("");
			setParentCategoryId(0);
			setFreshPathLink(true);
		}
	}, [activeLibrary]);

	useEffect(() => {
		//setting keys here
		const tempKeys = [];
		const displayKeys = [];
		list.map((item, index) => {
			let itemType = kbType, drag = false, canRemoveNode = false, showNext = false;
			if (typeof item.type !== "undefined") {
				itemType = item.type;
			}
			let listIcon = "";
			if (itemType == KBT_LIBRARY) {
				listIcon = <PrefixStyled color={kbColors[index]}>
					{item.name.charAt(0)}
				</PrefixStyled>
			} else {
				if (itemType == KBT_CATEGORY) {
					listIcon = <i className='icon-folder' style={{ marginRight: '10px' }}></i>
					if (treeMode){
						showNext = true;
					}
				} else {
					if (itemType == KBT_SUGGESTION && suggestMode) {
						listIcon = <SuggestIcon className="icon-suggest-knowledgebase" />
					} else {
						if ((itemType == KBT_QUESTION || itemType == "KBT_QUESTION_ALL") && !suggestMode) {
							itemType = KBT_QUESTION;
							listIcon = <ListBulletPoint />
						}
					}
				}
				if (treeMode || itemType === KBT_CATEGORY || itemType == KBT_QUESTION || !suggestMode) {
					drag = true;
					if (!treeMode && itemType == KBT_QUESTION) {
						drag = false;
					}
				}
			}
			let showSettings = kbType === 'KBT_QUESTION_ALL' ? false : true;
			if (((type == KBT_CATEGORY && itemType == KBT_QUESTION ) || itemType === KBT_SUGGESTION) || forErrand) {
				showSettings = false;
			}
			if (treeMode && type == KBT_CATEGORY) {
				if(!forErrand) {
					canRemoveNode = true;
				}
			}
			const nodeId = item.nodeId ? item.nodeId : item.id;
			tempKeys.push({
				id: item.id,
				displayName: item.name,
				nodeId: item.nodeId,
				type: itemType,
				icon: listIcon,
				draggable: drag,
				droppable: (itemType === KBT_CATEGORY && treeMode) || (itemType === KBT_LIBRARY && !treeMode) ? true : false,
				droppableType: (itemType === KBT_CATEGORY ? "mainDroppable" : "innerDroppable"),
				showTimeControlled: item.timeControlled,
				removable: canRemoveNode,
				showSettings,
				showNext,
				showLink: itemType === KBT_SUGGESTION ? true : false,
				link: item.displayId ? item.displayId : 0,
				errandId: item.errandId ? item.errandId : 0,
				threadId: item.threadId ? item.threadId : 0,
				showID: !forErrand && itemType === KBT_LIBRARY ? true : false,
				answer: forErrand && itemType === KBT_QUESTION ? item.answer : "",
				question: forErrand && itemType === KBT_QUESTION ? item.question : "",
				attachments: forErrand && itemType === KBT_QUESTION ? item.attachments : "",
				keyboardShortcut: forErrand && itemType === KBT_QUESTION ? item.keyboardShortcut : "",
				keyboardShortcutUseAlt: forErrand && itemType === KBT_QUESTION ? item.keyboardShortcutUseAlt : "",
				keyboardShortcutUseCtrl: forErrand && itemType === KBT_QUESTION ? item.keyboardShortcutUseCtrl : "",
				keyboardShortcutUseShift: forErrand && itemType === KBT_QUESTION ? item.keyboardShortcutUseShift : "",
				chatbots: forErrand && itemType === KBT_QUESTION ? item.chatbots : [],
				externalVotes: item.externalVotes,
				voteUp : item.voteUp,
				voteDown: item.voteDown,
			});
			displayKeys.push(item.id);
		});
		const dkstr = displayKeys.join(",");
		setDisplayKeysStr(dkstr);
		setKeys(tempKeys);
	}, [list, kbColors, treeMode]);

	const handleResetCategory = () => {
		onResetCategory();
		setActiveId(0);
	}

	const handleBackToLibrary = (e, subId) => {
		e.preventDefault();
		if(forErrand) {
			//back to library list
			const parameters = {};
			setActiveId(activeLibrary);
			onFetchKnowledgeBase(activeLibrary, KBT_LIBRARY, parameters);
			setToggleActiveLib(true);
		} else {
			if(subId) {
				onResetCategory();
				setSubLibId(0);
				setSubLibName("");
			} else {
				const parameters = {};
				onFetchKnowledgeBase(activeLibrary, KBT_LIBRARY, parameters);
				onResetCategory();
			}
		}
	}

	const handleBackToParent = (parentId) => {
		const parameters = {};
		if(parentId) {
			setActiveId(parentCategoryId);
			onResetCategory(true);
			onFetchKnowledgeBase(parentId, KBT_LIBRARY, parameters);
		} else {
			if(parentCategoryId) {
				setActiveId(parentCategoryId);
				onFetchKnowledgeBase(parentCategoryId, KBT_CATEGORY, parameters);
				setParentCategory("");
				setParentCategoryId(0);
			}
		}
	}

	const handleClickBox = (id, name, clickType) => {
		//when click on new, set active to true
		setActive(clickType+"-"+id);
		const parameters = {};
		if(clickType === KBT_LIBRARY) {
			if(activeId !== id) {
				//TREE MODE
				if(type === KBT_CATEGORY) {
					setActiveId(id);
					onSaveParentInfo(activeLibrary);
					setSubLibName(name);
					setSubLibId(id);
					setFreshPathLink(false);
				} else {
					setActiveId(id);
					setToggleActiveLib(true);
					setSubLibId(0);
					setSubLibName("");
					setFreshPathLink(true);
				}
				onFetchKnowledgeBase(id, clickType, parameters);
			} else {
				//NORMAL MODE
				onResetKnowledgeBase();
				setActiveId(0);
				setToggleActiveLib(false);
			}
		} else if(clickType === KBT_CATEGORY) {
			if(activeLibrary > 0) {
				setActiveId(id);
				onFetchKnowledgeBase(id, clickType, parameters);
			}
		} else {
			onEditKnowledgeBase(id, KBT_QUESTION);
			if(onSaveParentInfo) {
				if(activeCategory) {
					onSaveParentInfo(activeCategory);
				}
			}
		}
	}

	const handleClickSettings = (id, clickType, e) => {
		e.stopPropagation();
		let openType = clickType;
		if(clickType !== KBT_LIBRARY && clickType !== KBT_CATEGORY) {
			openType = KBT_QUESTION;
		}
		onEditKnowledgeBase(id, openType);
	}

	let activeStatus = active;
	if (type === KBT_LIBRARY) {
		if (!toggleActiveLib) {
			activeStatus = "";
		}
	} else if (type === KBT_CATEGORY) {
		activeStatus = "";
	}


	const handleRemoveSelKey = (node) => {
		setShowDeleteAlert(true);
		setToBeDeleted(node);
		const warningTitle = I('Are you sure you want to delete this instance?');
		const warning = "";
		setDeleteWarning(warningTitle);
		setDeleteWarningMsg(warning);
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		if(onRemoveNode) {
			let parameters = {};
			if(activeCategory > 0) {
				//delete node within category
				parameters = {
					containerType: KBT_CATEGORY,
					containerId: activeCategory,
					nodeId: toBeDeleted
				};
			} else if(activeLibrary > 0) {
				//delete node within library
				parameters = {
					containerType: KBT_LIBRARY,
					containerId: activeLibrary,
					nodeId: toBeDeleted
				};
			}
			onRemoveNode(parameters);
			const newKeys = keys.filter(o => o.nodeId !== toBeDeleted);
			const newDisplayKeys = newKeys.map(o => o.id);
			const dkstr = newDisplayKeys.join(",");
			setDisplayKeysStr(dkstr);
			setKeys(newKeys);
		}
		setToBeDeleted(0);
	}

	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
		setToBeDeleted(0);
	}

	const handleBackToList = () => {
		if(forErrand) {
			onResetKnowledgeBase();
			onResetCategory();
			setActiveId(0);
			setToggleActiveLib(false);
		}
	}

	const handleSearchInLIbrary = () => {
		if(onLoadSearchInLibrary) {
			onLoadSearchInLibrary();
		}
	}

	useEffect(() => {
		if(allCategoryList && allCategoryList.length > 0 && activeCategory) {
			const currentCat = allCategoryList.find(o => o.id == activeCategory);
			if(currentCat) {
				if(currentCat.parentCategories && currentCat.parentCategories.length > 0) {
					const parentCat = currentCat.parentCategories[0];
					if(parentCat) {
						setParentCategory(parentCat.name);
						setParentCategoryId(parentCat.id);
						setFreshPathLink(false);
					}
				}
			}
		}
	}, [treeList]);

	let breadcrumbTitle = "";
	let libName = "", catName = "";
	let showEmptyDataTxt = false;
	if(type === KBT_LIBRARY) {
		if(activeId && list && list.length > 0) {
			const libObj = list.find(o => o.id == activeId);
			if(libObj) {
				libName = libObj.name;
			}
		}
	} else if(type === KBT_CATEGORY) {
		showEmptyDataTxt = true;
		if(activeLibrary && allLibraryList && allLibraryList.length > 0) {
			const libObj = allLibraryList.find(o => o.id == activeLibrary);
			if(libObj) {
				libName = libObj.name;
			}
		}
		const currentCategoryList = allCategoryList;
		if(activeCategory && currentCategoryList && currentCategoryList.length > 0) {
			const catObj = currentCategoryList.find(o => o.id == activeCategory);
			if(catObj) {
				catName = catObj.name;
			}
		}

		let parentBreadCrumbTitle = I("Knowledge base");
		if(popupMode) {
			if(activeLibrary) {
				parentBreadCrumbTitle = <i className='icon-knowledgebase'></i>;
			}
		} else {
			if(libName || catName) {
				parentBreadCrumbTitle = <i className='icon-knowledgebase'></i>;
			}
		}

		if(freshPathLink) {
			catName = "";
		}
	
		if(libName && !catName) {
			breadcrumbTitle = <span>
			<BreadCrumbLink onClick={handleBackToList}>{parentBreadCrumbTitle}</BreadCrumbLink>
			{" > "}
			<BreadCrumbLink onClick={(e) => handleBackToLibrary(e, subLibId)} title={libName} >{libName}</BreadCrumbLink>
			{ subLibName ? " > " : "" }
			{ subLibName ? <BreadCrumbLink onClick={() => handleBackToParent(subLibId)} title={subLibName} >{subLibName}</BreadCrumbLink> : "" }
			{((list.length === 0 && activeCategory > 0)  || list.length>0 && list[0].type === 'question') &&  
        		allCategoryList.map((category) => (
          			category.id === activeCategory ? (
            		<>
              		{" > "}
              		<BreadCrumbLink key={category.id}>{category.name}</BreadCrumbLink>
           	 		</>
          			) : null
        		))
     		 }
			</span>;
		} else if (libName && catName) {
			breadcrumbTitle = <span>
			<BreadCrumbLink onClick={handleBackToList}>{parentBreadCrumbTitle}</BreadCrumbLink>
			{" > "}
			<BreadCrumbLink onClick={(e) => handleBackToLibrary(e, subLibId)} title={libName} >{libName}</BreadCrumbLink>
			{ subLibName ? " > " : "" }
			{ subLibName ? <BreadCrumbLink onClick={() => handleBackToParent(subLibId)} title={subLibName} >{subLibName}</BreadCrumbLink> : "" }
			{ parentCategory ? " > " : "" }
			{ parentCategory ? <BreadCrumbLink onClick={handleBackToParent} title={parentCategory} >{parentCategory}</BreadCrumbLink> : "" }
			{" > "}
			{catName}
			</span>;
		}
	}
	if(type === KBT_QUESTION) {
		const libObj = allLibraryList.find(o => o.id == activeLibrary);
		if(libObj) {
			libName = libObj.name;
		}
		const catObj = allCategoryList.find(o => o.id == activeCategory);
		if(catObj) {
			catName = catObj.name;
		}
		breadcrumbTitle = <span>
			{libName}
			{" > "}
			<BreadCrumbLink onClick={handleResetCategory} title={catName} >{catName}</BreadCrumbLink>
		</span>;
	}

	const spinnerWrapperStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		padding: '50% 0',
		fontSize: '20px',
		color: 'grey'
	};
	const baseDefaultWrapperStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		padding: '50% 0',
		fontSize: '20px',
		color: 'grey'
	};

	const defaultWrapperStyle = (forErrand) => {
		if(forErrand) {
			return baseDefaultWrapperStyle;
		} else {
			return {
				...baseDefaultWrapperStyle,
				outline: '3px dashed #c3c4c7',
			};
		}
	}

	let colorId = 0;
	if(treeMode && type === KBT_CATEGORY && libName) {
		colorId = allLibraryList.findIndex(o => o.id == activeLibrary);
	}
	if (fetchingList) {
		return (
			<div className={"board__container"}>
			{
				((type === KBT_CATEGORY || type === KBT_QUESTION) && activeLibrary) &&
				<span className='kb-content-panel-header'>
					<PrefixStyled color={kbColors[colorId]} className='kb-title-prefix'>
						{libName.charAt(0)}
					</PrefixStyled> {libName}
				</span>
			}
			{ breadcrumbTitle &&
				<div className="board__title" title={"title"}>
					{breadcrumbTitle}
				</div>
			}
			<div className='loading-spinner' style={spinnerWrapperStyle}>
				<Spinner />
			</div>
		</div>
		)
	}
	let showSearchIcon = false;
	if(type === KBT_CATEGORY && forErrand) {
		showSearchIcon = true;
	}
	let showTitle = true;
	if( showSearchResult) {
		showTitle = false;
	}
	return(
		<div className={"board__container"}>
			{
				((type === KBT_CATEGORY || type === KBT_QUESTION) && activeLibrary) ?
				<span className='kb-content-panel-header' hidden={!showTitle}>
					<PrefixStyled color={kbColors[colorId]} className='kb-title-prefix'>
						{libName.charAt(0)}
					</PrefixStyled>{libName}
					{ showSearchIcon ? <span className='kb-search-icon' onClick={handleSearchInLIbrary}><i className='icon-search'></i></span> : "" }
				</span> : ""
			}
			{ breadcrumbTitle &&
				<div className="board__title" hidden={!showTitle}>
					{breadcrumbTitle}
				</div>
			}
			<Droppable
				key={"droppableBoxes_" + droppableId}
				droppableId={"droppableBoxes_" + droppableId}
				isDropDisabled={type === KBT_LIBRARY && treeMode ? true : false}
				type="mainDroppable"
			>
				{(provided, snapshot) => (
					<div
						className={classNames("board__body", "kb-draggable-box", {
							isDraggingOver: snapshot.isDraggingOver,
						})}
						ref={provided.innerRef}
						style={{
							height: '585px', 
							overflowY: 'auto', 
							...getDroppableStyle(snapshot.isDraggingOver)
						}}
						{...provided.droppableProps}
					>
						{
						keys && keys.length > 0 ? (
							<CustomDraggableBox
								title={""}
								kbType={kbType}
								list={list}
								active={activeStatus}
								nodeIdentifier={nodeIdentifier}
								forErrand={forErrand}
								//innerClass={"kb-draggable-box"}
								referenceList={keys}
								placeholder={placeholder}
								displayList={displayKeysStr}
								onClickBox={handleClickBox}
								onClickSettings={handleClickSettings}
								onDelete={handleRemoveSelKey}
								onShowAnswerForErrand={props.onShowAnswerForErrand}
								treeMode={treeMode}
								disableDrag={disableDrag}
								disableDrop={
									kbType === KBT_CATEGORY && !treeMode ? true : false
								}
								isClickable={
									kbType === KBT_CATEGORY && !treeMode ? false : true
								}
								showRatings={kbType === "KBT_QUESTION_ALL" ? true : false}
								onClickNodeLink={props.onClickNodeLink}
								isExpanded = {popupMode}
								{...props}
							/>
						) : showEmptyDataTxt ? (
							<div style={defaultWrapperStyle(forErrand)}>
								{ forErrand ? I("No questions found") : I("Drop items here to begin") }
							</div>
						) : null
						}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
			<DeletePopup
				title={deleteWarning}
				msg={deleteWarningMsg}
				icon={'icon-caution'}
				show={showDeleteAlert}
				onDelete={handleConfirmDelete}
				onClose={handleCancelDelete}
			/>
		</div>
	)
});

DraggableKnowledgeBaseBox.displayName = 'DraggableKnowledgeBaseBox';

export default KnowledgeBaseList;
